import React from "react";
import { Table, Header, Loader, Message } from "semantic-ui-react";
//import ReactCountryFlag from "react-country-flag";
import { COUNTRY_CODES_CONVERSION } from "../utils/constants";

export class CleanSheet extends React.PureComponent {
  render() {
    if (this.props.error) {
      return (
        <Message
          negative
          className="text_center"
          content="Sorry, an error occured"
        ></Message>
      );
    }
    if (this.props.loading) {
      return (
        <div className="LoaderContainer">
          <Loader active content="Loading"></Loader>
        </div>
      );
    }
    if (this.props.data.length === 0) {
      return (
        <Message className="text_center" content="No data to display"></Message>
      );
    }
    return (
      <Table striped className="shootingtime_results_table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell />
            <Table.HeaderCell />
            <Table.HeaderCell>5/5</Table.HeaderCell>
            <Table.HeaderCell>Percent</Table.HeaderCell>
            <Table.HeaderCell>Nb.</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.data.map((athlete, index) => (
            <Table.Row
              key={athlete.id}
              onClick={() => this.props.showAthleteCard(true, athlete.id)}
              className="athlete_clickable"
            >
              <Table.Cell>
                <span
                  className={
                    athlete.ranking === 1
                      ? `score_place score_place--grey`
                      : "score_place"
                  }
                >
                  {athlete.ranking}
                </span>
              </Table.Cell>
              <Table.Cell>
                {COUNTRY_CODES_CONVERSION[athlete.nationality] && (
                  <img
                    src={`static/img/${
                      COUNTRY_CODES_CONVERSION[athlete.nationality]["code"]
                    }.png`}
                    alt={COUNTRY_CODES_CONVERSION[athlete.nationality]["name"]}
                  />
                )}
              </Table.Cell>
              <Table.Cell>
                <Header as="h3">
                  {athlete.family_name} {athlete.given_name}
                  {athlete.isu25 && <span className="u25_span">U23</span>}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <span className="score_points_rank">
                  {athlete.clean_fivefive}
                </span>
              </Table.Cell>
              <Table.Cell>
                <span className="score_points_rank">
                  {athlete.clean_percent}
                  {"%"}
                </span>
              </Table.Cell>
              <Table.Cell>
                <span className="score_points_time">{athlete.clean_races}</span>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}
