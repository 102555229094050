import React from "react";
import {
  Icon,
  Message,
  Loader,
  Table,
  Header,
  Progress,
} from "semantic-ui-react";
import { COUNTRY_CODES_CONVERSION } from "../utils/constants";

export class CurrentTrends extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fullView: false,
    };
  }

  changeView = () => {
    this.props.handleSort("ranking");
    this.props.changeSingleRaceView();
    this.setState({ fullView: !this.state.fullView });
  };

  render() {
    if (this.props.error) {
      return (
        <Message
          negative
          className="text_center"
          content="Sorry, an error occured"
        ></Message>
      );
    }
    if (this.props.loading) {
      return (
        <div className="LoaderContainer">
          <Loader active content="Loading"></Loader>
        </div>
      );
    }
    if (this.props.data.length === 0) {
      return (
        <Message className="text_center" content="No data to display"></Message>
      );
    }
    return (
      <Table definition sortable striped className="singlerace_full_table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell className="no_order"></Table.HeaderCell>
            <Table.HeaderCell className="no_order"></Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                this.props.column === "diff_position"
                  ? this.props.direction
                  : null
              }
              onClick={this.props.handleSort("diff_position")}
            >
              Race Ranking
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                this.props.column === "diff_skiing"
                  ? this.props.direction
                  : null
              }
              onClick={this.props.handleSort("diff_skiing")}
            >
              Skiing Ranking
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                this.props.column === "diff_shootings"
                  ? this.props.direction
                  : null
              }
              onClick={this.props.handleSort("diff_shootings")}
            >
              Shooting %
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.data.map((athlete) => (
            <Table.Row
              key={athlete.id}
              onClick={() => this.props.showAthleteCard(true, athlete.id)}
              className="athlete_clickable"
            >
              <Table.Cell>
                <span className="score_place">{athlete.ranking}</span>
              </Table.Cell>
              <Table.Cell>
                {COUNTRY_CODES_CONVERSION[athlete.nationality] && (
                  <img
                    src={`static/img/${
                      COUNTRY_CODES_CONVERSION[athlete.nationality]["code"]
                    }.png`}
                    alt={COUNTRY_CODES_CONVERSION[athlete.nationality]["name"]}
                  />
                )}
              </Table.Cell>
              <Table.Cell>
                <Header as="h3">
                  {athlete.family_name} {athlete.given_name}
                  {athlete.isu25 && <span className="u25_span">U23</span>}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <span className="score_points_time">
                  {athlete.old_position > athlete.new_position ? (
                    <span className="greenArrow">
                      <Icon name="arrow up" />
                    </span>
                  ) : (
                    <>
                      {athlete.old_position === athlete.new_position ? (
                        <span className="greyArrow">
                          <Icon name="arrows alternate horizontal" />
                        </span>
                      ) : (
                        <span className="redArrow">
                          <Icon name="arrow down" />
                        </span>
                      )}
                    </>
                  )}
                  {athlete.old_position > athlete.new_position ? (
                    <span className="greenArrow">
                      +{athlete.old_position - athlete.new_position}
                    </span>
                  ) : (
                    <>
                      {athlete.old_position === athlete.new_position ? (
                        <span className="greyArrow">
                          {athlete.old_position - athlete.new_position}
                        </span>
                      ) : (
                        <span className="redArrow">
                          {athlete.old_position - athlete.new_position}
                        </span>
                      )}
                    </>
                  )}
                </span>{" "}
                <Progress
                  size="small"
                  percent={
                    athlete.old_position > athlete.new_position
                      ? athlete.diff_position
                      : -1 * athlete.diff_position
                  }
                  success={
                    athlete.old_position > athlete.new_position ? true : false
                  }
                  error={
                    athlete.old_position < athlete.new_position ? true : false
                  }
                ></Progress>
                last: {athlete.old_position} - current: {athlete.new_position}
              </Table.Cell>
              <Table.Cell>
                {!athlete.old_skiing ||
                !athlete.new_skiing ||
                athlete.diff_skiing === -100000 ? (
                  <Message className="text_center" content="No data"></Message>
                ) : (
                  <>
                    <span className="score_points_time">
                      {athlete.old_skiing > athlete.new_skiing ? (
                        <span className="greenArrow">
                          <Icon name="arrow up" />
                        </span>
                      ) : (
                        <>
                          {athlete.old_skiing === athlete.new_skiing ? (
                            <span className="greyArrow">
                              <Icon name="arrows alternate horizontal" />
                            </span>
                          ) : (
                            <span className="redArrow">
                              <Icon name="arrow down" />
                            </span>
                          )}
                        </>
                      )}
                      {athlete.old_skiing > athlete.new_skiing ? (
                        <span className="greenArrow">
                          +{athlete.old_skiing - athlete.new_skiing}
                        </span>
                      ) : (
                        <>
                          {athlete.old_skiing === athlete.new_skiing ? (
                            <span className="greyArrow">
                              {athlete.old_skiing - athlete.new_skiing}
                            </span>
                          ) : (
                            <span className="redArrow">
                              {athlete.old_skiing - athlete.new_skiing}
                            </span>
                          )}
                        </>
                      )}
                    </span>{" "}
                    <Progress
                      size="small"
                      percent={
                        athlete.old_skiing > athlete.new_skiing
                          ? athlete.diff_skiing
                          : -1 * athlete.diff_skiing
                      }
                      success={
                        athlete.old_skiing > athlete.new_skiing ? true : false
                      }
                      error={
                        athlete.old_skiing < athlete.new_skiing ? true : false
                      }
                    ></Progress>
                    last: {athlete.old_skiing} - current: {athlete.new_skiing}
                  </>
                )}
              </Table.Cell>
              <Table.Cell>
                <span className="score_points_time">
                  {athlete.new_shootings > athlete.old_shootings ? (
                    <span className="greenArrow">
                      <Icon name="arrow up" />
                    </span>
                  ) : (
                    <>
                      {athlete.new_shootings === athlete.old_shootings ? (
                        <span className="greyArrow">
                          <Icon name="arrows alternate horizontal" />
                        </span>
                      ) : (
                        <span className="redArrow">
                          <Icon name="arrow down" />
                        </span>
                      )}
                    </>
                  )}
                  {athlete.new_shootings > athlete.old_shootings ? (
                    <span className="greenArrow">
                      +{athlete.diff_shootings}%
                    </span>
                  ) : (
                    <>
                      {athlete.new_shootings === athlete.old_shootings ? (
                        <span className="greyArrow">
                          {athlete.diff_shootings}%
                        </span>
                      ) : (
                        <span className="redArrow">
                          {athlete.diff_shootings}%
                        </span>
                      )}
                    </>
                  )}
                </span>{" "}
                <Progress
                  size="small"
                  percent={
                    athlete.diff_shootings >= 0
                      ? athlete.diff_shootings
                      : -1 * athlete.diff_shootings
                  }
                  success={athlete.diff_shootings > 0 ? true : false}
                  error={athlete.diff_shootings < 0 ? true : false}
                ></Progress>
                last: {athlete.old_shootings}% - current:{" "}
                {athlete.new_shootings}%
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}
