import React from "react";
import { Table, Header, Loader, Icon, Message } from "semantic-ui-react";
import { COUNTRY_CODES_CONVERSION } from "../utils/constants";

/*const RESULT = [
  {
    category: "OG",
    discipline: "SPRINT",
    result: [
      {
        name: "Johannes Thingnes BOE",
        nationality: "NOR",
        isu25: false,
        id: 2,
      },
      {
        name: "Johannes Thingnes BOE",
        nationality: "NOR",
        isu25: false,
        id: 2,
      },
      {
        name: "Johannes Thingnes BOE",
        nationality: "NOR",
        isu25: false,
        id: 2,
      },
    ],
  },
  {
    category: "OG",
    discipline: "PURSUIT",
    result: [
      {
        name: "Johannes Thingnes BOE",
        nationality: "NOR",
        isu25: true,
        id: 2,
      },
      {
        name: "Johannes Thingnes BOE",
        nationality: "NOR",
        isu25: true,
        id: 2,
      },
      {
        name: "Johannes Thingnes BOE",
        nationality: "NOR",
        isu25: true,
        id: 2,
      },
    ],
  },
  {
    category: "OG",
    discipline: "MASS-START",
    result: [
      {
        name: "Johannes Thingnes BOE",
        nationality: "NOR",
        isu25: false,
        id: 2,
      },
      {
        name: "Johannes Thingnes BOE",
        nationality: "NOR",
        isu25: false,
        id: 2,
      },
      {
        name: "Johannes Thingnes BOE",
        nationality: "NOR",
        isu25: false,
        id: 2,
      },
    ],
  },
  {
    category: "OG",
    discipline: "INDIVIDUAL",
    result: [
      {
        name: "Johannes Thingnes BOE",
        nationality: "NOR",
        isu25: false,
        id: 2,
      },
      {
        name: "Johannes Thingnes BOE",
        nationality: "NOR",
        isu25: false,
        id: 2,
      },
      {
        name: "Johannes Thingnes BOE",
        nationality: "NOR",
        isu25: false,
        id: 2,
      },
    ],
  },
];*/

export class SpecialEvent extends React.PureComponent {
  drawStars(number) {
    let rows = [];
    for (let i = 0; i < number; i++) {
      rows.push(<Icon key={i} name="certificate" />);
    }
    return rows;
  }
  render() {
    if (this.props.error) {
      return (
        <Message
          negative
          className="text_center"
          content="Sorry, an error occured"
        ></Message>
      );
    }
    if (this.props.loading) {
      return (
        <div className="LoaderContainer">
          <Loader active content="Loading"></Loader>
        </div>
      );
    }
    if (this.props.data.length === 0) {
      return (
        <Message className="text_center" content="No data to display"></Message>
      );
    }
    return (
      <Table striped className="medalstable_results_table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>
              <span className="trophy trophy_gold">
                <Icon name="certificate" />
              </span>
              Gold
            </Table.HeaderCell>
            <Table.HeaderCell>
              <span className="trophy trophy_silver">
                <Icon name="certificate" />
              </span>
              Silver
            </Table.HeaderCell>
            <Table.HeaderCell>
              <span className="trophy trophy_bronze">
                <Icon name="certificate" />
              </span>
              Bronze
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.data.map((result) => (
            <Table.Row key={result.discipline}>
              <Table.Cell>
                <Header as="h3">{result.discipline}</Header>
              </Table.Cell>
              <Table.Cell>
                {result.result[0] ? (
                  <Table
                    onClick={() =>
                      this.props.showAthleteCard(true, result.result[0].id)
                    }
                    className="athlete_clickable"
                  >
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          {COUNTRY_CODES_CONVERSION[
                            result.result[0].nationality
                          ] && (
                            <img
                              src={`static/img/${
                                COUNTRY_CODES_CONVERSION[
                                  result.result[0].nationality
                                ]["code"]
                              }.png`}
                              alt={
                                COUNTRY_CODES_CONVERSION[
                                  result.result[0].nationality
                                ]["name"]
                              }
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          <Header as="h3">
                            {result.result[0].name}
                            {result.result[0].isu25 && (
                              <span className="u25_span">U23</span>
                            )}
                          </Header>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                ) : (
                  <Message
                    className="text_center"
                    content="No data to display"
                  ></Message>
                )}
              </Table.Cell>
              <Table.Cell>
                {result.result[1] ? (
                  <Table
                    onClick={() =>
                      this.props.showAthleteCard(true, result.result[1].id)
                    }
                    className="athlete_clickable"
                  >
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          {COUNTRY_CODES_CONVERSION[
                            result.result[1].nationality
                          ] && (
                            <img
                              src={`static/img/${
                                COUNTRY_CODES_CONVERSION[
                                  result.result[1].nationality
                                ]["code"]
                              }.png`}
                              alt={
                                COUNTRY_CODES_CONVERSION[
                                  result.result[1].nationality
                                ]["name"]
                              }
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          <Header as="h3">
                            {result.result[1].name}
                            {result.result[1].isu25 && (
                              <span className="u25_span">U23</span>
                            )}
                          </Header>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                ) : (
                  <Message
                    className="text_center"
                    content="No data to display"
                  ></Message>
                )}
              </Table.Cell>
              <Table.Cell>
                {result.result[2] ? (
                  <Table
                    onClick={() =>
                      this.props.showAthleteCard(true, result.result[2].id)
                    }
                    className="athlete_clickable"
                  >
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          {COUNTRY_CODES_CONVERSION[
                            result.result[2].nationality
                          ] && (
                            <img
                              src={`static/img/${
                                COUNTRY_CODES_CONVERSION[
                                  result.result[2].nationality
                                ]["code"]
                              }.png`}
                              alt={
                                COUNTRY_CODES_CONVERSION[
                                  result.result[2].nationality
                                ]["name"]
                              }
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          <Header as="h3">
                            {result.result[2].name}
                            {result.result[2].isu25 && (
                              <span className="u25_span">U23</span>
                            )}
                          </Header>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                ) : (
                  <Message
                    className="text_center"
                    content="No data to display"
                  ></Message>
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}
