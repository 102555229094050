import React from "react";
import {
  Grid,
  Segment,
  Icon,
  Dropdown,
  Message,
  Loader,
  Divider,
  Table,
  Header,
} from "semantic-ui-react";
import { WEATHER_ICONS, COUNTRY_CODES_CONVERSION } from "../utils/constants";

export class SingleRace extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fullView: false,
    };
  }

  changeView = () => {
    this.props.handleSort("ranking");
    this.props.changeSingleRaceView();
    this.setState({ fullView: !this.state.fullView });
  };

  render() {
    if (this.props.loading) {
      return (
        <div className="LoaderContainer">
          <Loader active content="Loading"></Loader>
        </div>
      );
    }
    if (this.props.data.length === 0) {
      return (
        <Message
          className="text_center"
          content="No races to display"
        ></Message>
      );
    }
    return (
      <Segment>
        <Grid className="singlerace_header_grid">
          <Grid.Row columns={3}>
            <Grid.Column
              width={6}
              textAlign="right"
              className="singlerace_header_label"
            >
              <span className="race_list-icon">
                {" "}
                <span className="float-left">
                  <Icon name="repeat" />
                </span>{" "}
                Last Races:
              </span>
            </Grid.Column>
            <Grid.Column width={6} className="singlerace_header_dropdown">
              <Dropdown
                placeholder="Select items to filter datas"
                fluid
                search
                selection
                options={this.props.data}
                onChange={this.props.handleRaceChange}
                loading={this.props.loading || this.props.loading_race}
                value={this.props.race_id}
                className="race_list-dropdown"
                disabled={this.props.loading_race}
              />
            </Grid.Column>
            <Grid.Column width={4} className="race_list-plus">
              <Icon
                name={
                  this.props.single_race_fullView
                    ? "minus circle"
                    : "plus circle"
                }
                onClick={this.changeView}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className="singlerace-container">
          {this.props.loading_race ? (
            <div className="LoaderContainer">
              <Loader active content="Loading"></Loader>
            </div>
          ) : (
            <>
              {this.props.error_race ? (
                <>
                  <Divider />
                  <Message
                    negative
                    className="text_center"
                    content="Sorry, an error occured"
                  ></Message>
                </>
              ) : (
                <>
                  <Divider />
                  <Grid divided className="singlerace_icons_grid">
                    <Grid.Row columns={5} className="grid-center">
                      <Grid.Column>
                        <i
                          className={`wi ${
                            WEATHER_ICONS[this.props.data_race.weather]
                              ? WEATHER_ICONS[this.props.data_race.weather]
                              : "wi-cloud"
                          } grid-center-icon-weather`}
                        ></i>
                      </Grid.Column>
                      <Grid.Column>
                        <Icon
                          name="arrows alternate horizontal"
                          className="icon-weather-semantic"
                        ></Icon>
                        {this.props.data_race && this.props.data_race.km}
                      </Grid.Column>
                      <Grid.Column>
                        {" "}
                        <i className="wi wi-thermometer grid-center-icon"></i>{" "}
                        {this.props.data_race &&
                          this.props.data_race.temperature}
                      </Grid.Column>
                      <Grid.Column>
                        <i className="wi wi-strong-wind grid-center-icon"></i>{" "}
                        {this.props.data_race &&
                          this.props.data_race.wind &&
                          this.props.data_race.wind.speed}
                      </Grid.Column>
                      <Grid.Column>
                        <Icon
                          name="location arrow"
                          className="icon-weather-semantic"
                        ></Icon>
                        {this.props.data_race &&
                          this.props.data_race.data_height_total_climb}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Divider />
                  {!this.props.single_race_fullView ? (
                    <Grid divided>
                      <Grid.Row
                        columns={6}
                        className="grid-center singlerace_min_grid"
                      >
                        <Grid.Column
                          className="click_raceResult"
                          onClick={() =>
                            this.props.showAthleteCard(
                              true,
                              this.props.data_race_not_filtered.results[0]
                                .athlete.id
                            )
                          }
                        >
                          {
                            this.props.data_race_not_filtered.results[0]
                              .position
                          }{" "}
                          -
                          {this.props.data_race_not_filtered.results[0].athlete
                            .photo ? (
                            <div
                              className={
                                "athlete_picture-container athlete-min-raceresult athlete_picture-container" +
                                this.props.data_race_not_filtered.results[0]
                                  .position
                              }
                            >
                              <img
                                className="athlete_picture"
                                alt={
                                  this.props.data_race_not_filtered.results[0]
                                    .athlete.family_name
                                }
                                title={
                                  this.props.data_race_not_filtered.results[0]
                                    .athlete.family_name +
                                  " " +
                                  this.props.data_race_not_filtered.results[0]
                                    .athlete.given_name
                                }
                                src={
                                  this.props.data_race_not_filtered.results[0]
                                    .athlete.photo
                                }
                              ></img>
                            </div>
                          ) : (
                            <div
                              className={
                                "athlete_picture-container athlete_picture-container--empty athlete_picture-container" +
                                this.props.data_race_not_filtered.results[0]
                                  .position
                              }
                            >
                              <span className="athlete_picture"></span>
                            </div>
                          )}{" "}
                          {COUNTRY_CODES_CONVERSION[
                            this.props.data_race_not_filtered.results[0].athlete
                              .nationality
                          ] && (
                            <img
                              src={`static/img/${
                                COUNTRY_CODES_CONVERSION[
                                  this.props.data_race_not_filtered.results[0]
                                    .athlete.nationality
                                ]["code"]
                              }.png`}
                              alt={
                                COUNTRY_CODES_CONVERSION[
                                  this.props.data_race_not_filtered.results[0]
                                    .athlete.nationality
                                ]["name"]
                              }
                              className={"ctry_flag"}
                            />
                          )}
                          <div className="race-ranking-name">
                            {
                              this.props.data_race_not_filtered.results[0]
                                .athlete.family_name
                            }{" "}
                            {
                              this.props.data_race_not_filtered.results[0]
                                .athlete.given_name
                            }
                            {this.props.data_race_not_filtered.results[0]
                              .isu25 && <span className="u25_span">U23</span>}
                          </div>
                        </Grid.Column>
                        <Grid.Column
                          className="click_raceResult"
                          onClick={() =>
                            this.props.showAthleteCard(
                              true,
                              this.props.data_race_not_filtered.results[1]
                                .athlete.id
                            )
                          }
                        >
                          {
                            this.props.data_race_not_filtered.results[1]
                              .position
                          }{" "}
                          -
                          {this.props.data_race_not_filtered.results[1].athlete
                            .photo ? (
                            <div
                              className={
                                "athlete_picture-container athlete-min-raceresult athlete_picture-container" +
                                this.props.data_race_not_filtered.results[1]
                                  .position
                              }
                            >
                              <img
                                className="athlete_picture"
                                alt={
                                  this.props.data_race_not_filtered.results[1]
                                    .athlete.family_name
                                }
                                title={
                                  this.props.data_race_not_filtered.results[1]
                                    .athlete.family_name +
                                  " " +
                                  this.props.data_race_not_filtered.results[1]
                                    .athlete.given_name
                                }
                                src={
                                  this.props.data_race_not_filtered.results[1]
                                    .athlete.photo
                                }
                              ></img>
                              <br />
                            </div>
                          ) : (
                            <div
                              className={
                                "athlete_picture-container athlete_picture-container--empty athlete_picture-container" +
                                this.props.data_race_not_filtered.results[1]
                                  .position
                              }
                            >
                              <span className="athlete_picture"></span>
                            </div>
                          )}{" "}
                          {COUNTRY_CODES_CONVERSION[
                            this.props.data_race_not_filtered.results[1].athlete
                              .nationality
                          ] && (
                            <img
                              src={`static/img/${
                                COUNTRY_CODES_CONVERSION[
                                  this.props.data_race_not_filtered.results[1]
                                    .athlete.nationality
                                ]["code"]
                              }.png`}
                              alt={
                                COUNTRY_CODES_CONVERSION[
                                  this.props.data_race_not_filtered.results[1]
                                    .athlete.nationality
                                ]["name"]
                              }
                              className={"ctry_flag"}
                            />
                          )}
                          <div className="race-ranking-name">
                            {
                              this.props.data_race_not_filtered.results[1]
                                .athlete.family_name
                            }{" "}
                            {
                              this.props.data_race_not_filtered.results[1]
                                .athlete.given_name
                            }
                            {this.props.data_race_not_filtered.results[1]
                              .isu25 && <span className="u25_span">U23</span>}
                          </div>
                        </Grid.Column>
                        <Grid.Column
                          className="click_raceResult"
                          onClick={() =>
                            this.props.showAthleteCard(
                              true,
                              this.props.data_race_not_filtered.results[2]
                                .athlete.id
                            )
                          }
                        >
                          {
                            this.props.data_race_not_filtered.results[2]
                              .position
                          }{" "}
                          -
                          {this.props.data_race_not_filtered.results[2].athlete
                            .photo ? (
                            <div
                              className={
                                "athlete_picture-container athlete-min-raceresult athlete_picture-container" +
                                this.props.data_race_not_filtered.results[2]
                                  .position
                              }
                            >
                              <img
                                className="athlete_picture"
                                alt={
                                  this.props.data_race_not_filtered.results[2]
                                    .athlete.family_name
                                }
                                title={
                                  this.props.data_race_not_filtered.results[2]
                                    .athlete.family_name +
                                  " " +
                                  this.props.data_race_not_filtered.results[2]
                                    .athlete.given_name
                                }
                                src={
                                  this.props.data_race_not_filtered.results[2]
                                    .athlete.photo
                                }
                              ></img>
                            </div>
                          ) : (
                            <div
                              className={
                                "athlete_picture-container athlete_picture-container--empty athlete_picture-container" +
                                this.props.data_race_not_filtered.results[2]
                                  .position
                              }
                            >
                              <span className="athlete_picture"></span>
                            </div>
                          )}{" "}
                          {COUNTRY_CODES_CONVERSION[
                            this.props.data_race_not_filtered.results[2].athlete
                              .nationality
                          ] && (
                            <img
                              src={`static/img/${
                                COUNTRY_CODES_CONVERSION[
                                  this.props.data_race_not_filtered.results[2]
                                    .athlete.nationality
                                ]["code"]
                              }.png`}
                              alt={
                                COUNTRY_CODES_CONVERSION[
                                  this.props.data_race_not_filtered.results[2]
                                    .athlete.nationality
                                ]["name"]
                              }
                              className={"ctry_flag"}
                            />
                          )}
                          <div className="race-ranking-name">
                            {
                              this.props.data_race_not_filtered.results[2]
                                .athlete.family_name
                            }{" "}
                            {
                              this.props.data_race_not_filtered.results[2]
                                .athlete.given_name
                            }
                            {this.props.data_race_not_filtered.results[2]
                              .isu25 && <span className="u25_span">U23</span>}
                          </div>
                        </Grid.Column>
                        <Grid.Column
                          className="click_raceResult"
                          onClick={() =>
                            this.props.showAthleteCard(
                              true,
                              this.props.data_race_not_filtered.results[3]
                                .athlete.id
                            )
                          }
                        >
                          {
                            this.props.data_race_not_filtered.results[3]
                              .position
                          }{" "}
                          -
                          {this.props.data_race_not_filtered.results[3].athlete
                            .photo ? (
                            <div
                              className={
                                "athlete_picture-container athlete-min-raceresult athlete_picture-container" +
                                this.props.data_race_not_filtered.results[3]
                                  .position
                              }
                            >
                              <img
                                className="athlete_picture"
                                alt={
                                  this.props.data_race_not_filtered.results[3]
                                    .athlete.family_name
                                }
                                title={
                                  this.props.data_race_not_filtered.results[3]
                                    .athlete.family_name +
                                  " " +
                                  this.props.data_race_not_filtered.results[3]
                                    .athlete.given_name
                                }
                                src={
                                  this.props.data_race_not_filtered.results[3]
                                    .athlete.photo
                                }
                              ></img>
                            </div>
                          ) : (
                            <div
                              className={
                                "athlete_picture-container athlete_picture-container--empty athlete_picture-container" +
                                this.props.data_race_not_filtered.results[3]
                                  .position
                              }
                            >
                              <span className="athlete_picture"></span>
                            </div>
                          )}{" "}
                          {COUNTRY_CODES_CONVERSION[
                            this.props.data_race_not_filtered.results[3].athlete
                              .nationality
                          ] && (
                            <img
                              src={`static/img/${
                                COUNTRY_CODES_CONVERSION[
                                  this.props.data_race_not_filtered.results[3]
                                    .athlete.nationality
                                ]["code"]
                              }.png`}
                              alt={
                                COUNTRY_CODES_CONVERSION[
                                  this.props.data_race_not_filtered.results[3]
                                    .athlete.nationality
                                ]["name"]
                              }
                              className={"ctry_flag"}
                            />
                          )}
                          <div className="race-ranking-name">
                            {
                              this.props.data_race_not_filtered.results[3]
                                .athlete.family_name
                            }{" "}
                            {
                              this.props.data_race_not_filtered.results[3]
                                .athlete.given_name
                            }
                            {this.props.data_race_not_filtered.results[3]
                              .isu25 && <span className="u25_span">U23</span>}
                          </div>
                        </Grid.Column>
                        <Grid.Column
                          className="click_raceResult"
                          onClick={() =>
                            this.props.showAthleteCard(
                              true,
                              this.props.data_race_not_filtered.results[4]
                                .athlete.id
                            )
                          }
                        >
                          {
                            this.props.data_race_not_filtered.results[4]
                              .position
                          }{" "}
                          -
                          {this.props.data_race_not_filtered.results[4].athlete
                            .photo ? (
                            <div
                              className={
                                "athlete_picture-container athlete-min-raceresult athlete_picture-container" +
                                this.props.data_race_not_filtered.results[4]
                                  .position
                              }
                            >
                              <img
                                className="athlete_picture"
                                alt={
                                  this.props.data_race_not_filtered.results[4]
                                    .athlete.family_name
                                }
                                title={
                                  this.props.data_race_not_filtered.results[4]
                                    .athlete.family_name +
                                  " " +
                                  this.props.data_race_not_filtered.results[4]
                                    .athlete.given_name
                                }
                                src={
                                  this.props.data_race_not_filtered.results[4]
                                    .athlete.photo
                                }
                              ></img>
                            </div>
                          ) : (
                            <div
                              className={
                                "athlete_picture-container athlete_picture-container--empty athlete_picture-container" +
                                this.props.data_race_not_filtered.results[4]
                                  .position
                              }
                            >
                              <span className="athlete_picture"></span>
                            </div>
                          )}{" "}
                          {COUNTRY_CODES_CONVERSION[
                            this.props.data_race_not_filtered.results[4].athlete
                              .nationality
                          ] && (
                            <img
                              src={`static/img/${
                                COUNTRY_CODES_CONVERSION[
                                  this.props.data_race_not_filtered.results[4]
                                    .athlete.nationality
                                ]["code"]
                              }.png`}
                              alt={
                                COUNTRY_CODES_CONVERSION[
                                  this.props.data_race_not_filtered.results[4]
                                    .athlete.nationality
                                ]["name"]
                              }
                              className={"ctry_flag"}
                            />
                          )}
                          <div className="race-ranking-name">
                            {
                              this.props.data_race_not_filtered.results[4]
                                .athlete.family_name
                            }{" "}
                            {
                              this.props.data_race_not_filtered.results[4]
                                .athlete.given_name
                            }
                            {this.props.data_race_not_filtered.results[4]
                              .isu25 && <span className="u25_span">U23</span>}
                          </div>
                        </Grid.Column>
                        <Grid.Column
                          className="click_raceResult"
                          onClick={() =>
                            this.props.showAthleteCard(
                              true,
                              this.props.data_race_not_filtered.results[5]
                                .athlete.id
                            )
                          }
                        >
                          {
                            this.props.data_race_not_filtered.results[5]
                              .position
                          }{" "}
                          -
                          {this.props.data_race_not_filtered.results[5].athlete
                            .photo ? (
                            <div
                              className={
                                "athlete_picture-container athlete-min-raceresult athlete_picture-container" +
                                this.props.data_race_not_filtered.results[5]
                                  .position
                              }
                            >
                              <img
                                className="athlete_picture"
                                alt={
                                  this.props.data_race_not_filtered.results[5]
                                    .athlete.family_name
                                }
                                title={
                                  this.props.data_race_not_filtered.results[5]
                                    .athlete.family_name +
                                  " " +
                                  this.props.data_race_not_filtered.results[5]
                                    .athlete.given_name
                                }
                                src={
                                  this.props.data_race_not_filtered.results[5]
                                    .athlete.photo
                                }
                              ></img>
                            </div>
                          ) : (
                            <div
                              className={
                                "athlete_picture-container athlete_picture-container--empty athlete_picture-container" +
                                this.props.data_race_not_filtered.results[5]
                                  .position
                              }
                            >
                              <span className="athlete_picture"></span>
                            </div>
                          )}{" "}
                          {COUNTRY_CODES_CONVERSION[
                            this.props.data_race_not_filtered.results[5].athlete
                              .nationality
                          ] && (
                            <img
                              src={`static/img/${
                                COUNTRY_CODES_CONVERSION[
                                  this.props.data_race_not_filtered.results[5]
                                    .athlete.nationality
                                ]["code"]
                              }.png`}
                              alt={
                                COUNTRY_CODES_CONVERSION[
                                  this.props.data_race_not_filtered.results[5]
                                    .athlete.nationality
                                ]["name"]
                              }
                              className={"ctry_flag"}
                            />
                          )}
                          <div className="race-ranking-name">
                            {
                              this.props.data_race_not_filtered.results[5]
                                .athlete.family_name
                            }{" "}
                            {
                              this.props.data_race_not_filtered.results[5]
                                .athlete.given_name
                            }{" "}
                            {this.props.data_race_not_filtered.results[5]
                              .isu25 && <span className="u25_span">U23</span>}
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  ) : (
                    <>
                      {this.props.data_race.results.length === 0 ? (
                        <Message
                          className="text_center"
                          content="No data to display"
                        ></Message>
                      ) : (
                        <div className="ranking-container">
                          <Table
                            sortable
                            striped
                            className="singlerace_full_table"
                          >
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell
                                  sorted={
                                    this.props.column === "ranking"
                                      ? this.props.direction
                                      : null
                                  }
                                  onClick={this.props.handleSort("ranking")}
                                >
                                  Ranking
                                </Table.HeaderCell>
                                <Table.HeaderCell className="no_order" />
                                {/*<Table.HeaderCell />*/}
                                <Table.HeaderCell className="no_order" />
                                <Table.HeaderCell
                                  sorted={
                                    this.props.column === "course_rank"
                                      ? this.props.direction
                                      : null
                                  }
                                  onClick={this.props.handleSort("course_rank")}
                                >
                                  Skiing time rank
                                </Table.HeaderCell>
                                <Table.HeaderCell className="no_order">
                                  Skiing time
                                </Table.HeaderCell>
                                <Table.HeaderCell className="no_order">
                                  Shootings
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                  sorted={
                                    this.props.column === "shooting_rank"
                                      ? this.props.direction
                                      : null
                                  }
                                  onClick={this.props.handleSort(
                                    "shooting_rank"
                                  )}
                                >
                                  Shooting time rank
                                </Table.HeaderCell>
                                <Table.HeaderCell className="no_order">
                                  Shooting time
                                </Table.HeaderCell>
                                <Table.HeaderCell className="no_order">
                                  Time Behind
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {this.props.data_race.results.map((result) => (
                                <Table.Row
                                  key={result.athlete.id}
                                  className="athlete_clickable"
                                  onClick={() =>
                                    this.props.showAthleteCard(
                                      true,
                                      result.athlete.id
                                    )
                                  }
                                >
                                  <Table.Cell>
                                    <span
                                      className={
                                        "score_place score_place" +
                                        result.position
                                      }
                                    >
                                      {result.position === 1000
                                        ? "N/A"
                                        : result.position}
                                    </span>
                                  </Table.Cell>
                                  <Table.Cell>
                                    {COUNTRY_CODES_CONVERSION[
                                      result.athlete.nationality
                                    ] && (
                                      <img
                                        src={`static/img/${
                                          COUNTRY_CODES_CONVERSION[
                                            result.athlete.nationality
                                          ]["code"]
                                        }.png`}
                                        alt={
                                          COUNTRY_CODES_CONVERSION[
                                            result.athlete.nationality
                                          ]["name"]
                                        }
                                      />
                                    )}
                                  </Table.Cell>
                                  {/*<Table.Cell>
                                {result.athlete.photo ? (
                                  <div
                                    className={
                                      result.athlete.ranking === 1
                                        ? `athlete_picture-container athlete_picture-container--grey`
                                        : "athlete_picture-container"
                                    }
                                  >
                                    <img
                                      className="athlete_picture"
                                      alt={result.athlete.family_name}
                                      title={
                                        result.athlete.family_name +
                                        " " +
                                        result.athlete.given_name
                                      }
                                      src={result.athlete.photo}
                                    ></img>
                                  </div>
                                ) : (
                                  <div className="athlete_picture-container athlete_picture-container--empty">
                                    <span className="athlete_picture"></span>
                                  </div>
                                )}
                                </Table.Cell>*/}
                                  <Table.Cell>
                                    <Header as="h3">
                                      {result.athlete.family_name}{" "}
                                      {result.athlete.given_name}
                                      {result.isu25 && (
                                        <span className="u25_span">U23</span>
                                      )}
                                    </Header>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <span className="score_points_time">
                                      {result.course_rank}
                                    </span>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <span className="score_points_time">
                                      {result.time_course}
                                    </span>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <span className="score_points_time">
                                      {result.shootings}
                                    </span>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <span className="score_points_time">
                                      {result.shooting_rank}
                                    </span>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <span className="score_points_time">
                                      {result.shooting_total}
                                    </span>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <span className="score_points_time">
                                      {result.time_result}
                                    </span>
                                  </Table.Cell>
                                  {/*<Table.Cell>
                <span className="score_points_time">
                  {result.athlete.total_points}
                </span>
              </Table.Cell>*/}
                                </Table.Row>
                              ))}
                            </Table.Body>
                          </Table>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Segment>
    );
  }
}
